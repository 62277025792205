<template>
  <div class="header">
    <div class="header-left">
      <!-- 折叠按钮 -->
      <div class="collapse-btn" @click="collapseChage">
        <i v-if="!collapse" class="el-icon-s-fold" style="font-size: 20px"></i>
        <i v-else class="el-icon-s-unfold" style="font-size: 20px"></i>
      </div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/main' }"
          >系统首页</el-breadcrumb-item
        >
        <el-breadcrumb-item
          v-if="erjiLuyouData.path !== ''"
          :to="{ path: erjiLuyouData.path }"
          >{{ erjiLuyouData.title }}</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="luyouData" :to="{ path: luyouData.path }">{{
          newLuyouTitle
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- <div class="logo">湖南恰学教育管理系统</div> -->
    <div class="header-right">
      <div class="header-user-con">
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip
            effect="dark"
            :content="fullscreen ? `取消全屏` : `全屏`"
            placement="bottom"
          >
            <i class="el-icon-rank" style="font-size: 20px"></i>
          </el-tooltip>
        </div>

        <div class="user-avator">
          <img
            src="../../assets/img/default.png"
            v-if="info.avatar == '' || info.avatar == null"
          />
          <img :src="info.avatar" alt="" v-else />
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ info.username }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided command="personal"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item divided command="loginout"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "@/utils/bus";
import store from "@/store";
import { userInfo, rUserLogout } from "@/api/user_info.js";
import { getToken, setToken, removeToken } from "@/utils/auth";
export default {
  data() {
    return {
      collapse: false,
      fullscreen: false,
      message: 2,
      info: {
        avatar: null,
        username: null
      },
      luyouData: {},
      newLuyouTitle: "",
      erjiLuyouData: {
        path: "",
        title: ""
      }
    };
  },
  created() {
    bus.$on("erji", index => {
      this.luyouData = {};
      this.newLuyouTitle = "";
      // console.log("二级路由", index);
      if (index == "rolelist") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "系统管理";
      }
      if (index == "Wechat_management") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "微信管理";
      } else if (index == "resources") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "课程中心";
      } else if (index == "userlist") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "用户管理";
      } else if (index == "advertisingList") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "营销中心";
      } else if (index == "news") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "新闻资讯";
      } else if (index == "japanese_curriculum") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "日语APP";
      } else if (index == "Korean") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "韩语APP";
      } else if (index == "French") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "法语APP";
      } else if (index == "Spanish") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "西班牙语APP";
      } else if (index == "German") {
        this.erjiLuyouData.path = "/" + index;
        this.erjiLuyouData.title = "德语APP";
      } else {
        this.erjiLuyouData.path = "";
      }
    });
    this.getUserInfo();
  },
  watch: {
    $route(newValue, oldValue) {
      // console.log("hearder页", newValue);
      if (newValue.path == "/main") {
        // window.location.reload();
        // history.go(0);
        this.erjiLuyouData = {
          path: "",
          title: ""
        };
        this.luyouData = {};
        this.newLuyouTitle = "";
        return;
      }
      this.luyouData = newValue;
      this.newLuyouTitle = newValue.meta.title;
    }
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        store.commit("setUserId", "-1");

        this.Logout();
        //  this.$router.push("Login");
      } else if (command == "personal") {
        this.$router.push("personal");
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    getUserInfo() {
      userInfo().then(res => {
        this.info.avatar = res.data.avatar;
        this.info.username = res.data.username;
        localStorage.setItem("role", res.data.role);
      });
    },
    async Logout() {
      const result = await this.$confirm("是否退出登录?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      rUserLogout().then(res => {
        if (res.code !== 1) {
          this.$message.error("退出登录失败,请稍后重试");
          return;
        }
        removeToken();
        this.$message.success("退出登录成功!");
        // this.$router.push("Login");
        this.$router.replace("Login");
      });
    }
  }
};
</script>

<style scoped>
.header {
  /* position: relative; */
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 22px;
  color: #fff;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
}
.el-breadcrumb /deep/ .el-breadcrumb__inner {
  color: #606266;
}
.header-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapse-btn {
  padding: 0 21px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  color: #323232;
}
.collapse-btn:hover {
  background-color: #f5f7fa;
}

.header-right {
  /* float: right; */
  padding-right: 50px;
  color: #323232;
}

.header-user-con {
  display: flex;
  height: 50px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.btn-bell .el-icon-bell {
  color: #fff;
}

.user-name {
  margin-left: 6px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #323232;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
