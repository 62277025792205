<template>
  <div class="sidebar">
    <!-- <div class="logo">
      湖南恰学教育管理系统
    </div> -->
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#414855"
      text-color="#8291A2"
      active-text-color="#ffffff"
      unique-opened
      router
      @open="kk"
    >
      <div class="logo" v-if="!collapse">湖南恰学教育管理系统</div>
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template
              slot="title"
              v-if="item.title == '日语APP' && num_japanese > 0"
            >
              <i class="icon iconfont el-icon-s-management grid-con-icon"></i>
              <el-badge :value="nums_japanese" class="item" slot="title"
                >日语APP</el-badge
              >
            </template>

            <template
              slot="title"
              v-else-if="item.title == '法语APP' && num_french > 0"
            >
              <i class="icon iconfont el-icon-s-management grid-con-icon"></i>
              <el-badge :value="nums_french" class="item" slot="title"
                >法语APP</el-badge
              >
            </template>

            <template slot="title" v-else>
              <i :class="item.icon" class="grid-con-icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <!-- <tem -->

            <template v-for="subItem in item.subs">
              <el-submenu
                v-if="subItem.subs"
                :index="subItem.index"
                :key="subItem.index"
              >
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}
                </el-menu-item>
              </el-submenu>
              <template v-else-if="subItem.title == '日语一对一' && num_japanese > 0">
                <el-menu-item
                  :index="subItem.index"
                  :key="subItem.index"
                >
                  <el-badge :value="nums_japanese" class="item">
                    日语一对一
                  </el-badge>
                </el-menu-item>
              </template>
              
              <template v-else-if="subItem.title == '法语一对一' && num_french > 0">
                <el-menu-item
                :index="subItem.index"
                :key="subItem.index"
              >
                <el-badge :value="nums_french" class="item">
                  法语一对一
                </el-badge>
              </el-menu-item>
              </template>
              <template v-else>
                <el-menu-item  :index="subItem.index" :key="subItem.index"
                  >{{ subItem.title }}
                </el-menu-item>
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon" class="grid-con-icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "@/utils/bus";
import store from "@/store";
import { mapState } from "vuex";
import { getCourseAppointment } from "@/api/onetooneAppointment.js";
import { selectCourseCate } from "@/api/courseApi.js";
export default {
  data() {
    return {
      collapse: false,
      num_japanese: 0,
      num_french: 0,
      items: [
        {
          icon: "icon iconfont icon-xiazai44",
          index: "main",
          title: "系统首页"
        },
        {
          icon: "icon iconfont el-icon-setting",
          index: "rolelist",
          title: "  系统管理",
          subs: [
            {
              index: "rolelist",
              title: "管理员"
            },
            {
              index: "jurisdiction",
              title: "权限管理"
            },
            {
              index: "freeLogin",
              title: "免密登录"
            },
            {
              index: "agreements",
              title: "协议管理"
            },
            {
              index: "smsLog",
              title: "短信日志"
            },
            {
              index: "sysLog",
              title: "系统日志"
            }
          ]
        },
        {
          icon: "icon iconfont el-icon-setting",
          index: "Wechat_management",
          title: "微信管理",
          subs: [
            {
              index: "Wechat_management",
              title: "自动回复"
            },
            {
              index: "custom_menu",
              title: "自定义菜单"
            }
          ]
        },
        {
          icon: "icon iconfont icon-biaoqiankuozhan_kecheng-131",
          index: "resources",
          title: "课程中心",
          subs: [
            // {
            //   index: "resources",
            //   title: "素材库"
            // },
            {
              index: "course",
              title: "课程列表"
            },

            {
              index: "orderList",
              title: "订单列表"
            },
            {
              index: "paymentList",
              title: "付款列表"
            },
            {
              index: "teacher_list",
              title: "老师列表"
            },
            {
              index: "questionnaire",
              title: "问卷管理"
            }
          ]
        },
        {
          icon: "icon iconfont icon-yonghuguanli",
          index: "userlist",
          title: "  用户管理",
          subs: [
            {
              index: "userlist",
              title: "用户列表"
            },
            {
              index: "userIntention",
              title: "意向用户"
            },
            {
              index: "oneToOne",
              title: "一对一学员"
            },
            {
              index: "onetooneTeacher",
              title: "一对一老师"
            },
            {
              index: "userLeave",
              title: "用户留言"
            },
            {
              index: "information",
              title: "信息收集"
            },
            {
              index: "collection_appList",
              title: "APP收集"
            },
            {
              index: "user_certificate",
              title: "用户证书"
            }
          ]
        },
        {
          icon: "icon iconfont icon--mk-marketing-content",
          index: "advertisingList",
          title: "营销中心",
          subs: [
            {
              index: "advertisingList",
              title: "广告管理"
            },
            {
              index: "service",
              title: "客服管理"
            },
            {
              index: "goodsList",
              title: "商品管理"
            },
            {
              index: "marketingCourse",
              title: "营销课程"
            },
            {
              index: "pointslist",
              title: "金币列表"
            },
            {
              index: "points_record",
              title: "金币日志"
            },
            {
              index: "points_order",
              title: "金币订单"
            },
            {
              index: "points_mall",
              title: "金币商城"
            },
            {
              index: "taskList",
              title: "任务记录"
            },
            {
              index: "prizeList",
              title: "奖品列表"
            },
            {
              index: "winning_record",
              title: "中奖记录"
            },
            {
              index: "couponsList",
              title: "优惠券列表"
            }
          ]
        },

        {
          icon: "icon iconfont el-icon-chat-dot-round",
          index: "news",
          title: "新闻资讯",
          subs: [
            {
              index: "news",
              title: "新闻列表"
            }
          ]
        },
        {
          icon: "icon iconfont el-icon-s-management",
          index: "japanese_curriculum",
          title: "日语APP",
          subs: [
            {
              index: "japanese_resources",
              title: "素材库"
            },
            {
              index: "japanese_onetoone_appointment",
              title: "日语一对一"
            },
            {
              index: "fifty_notes",
              title: "五十音列表"
            },
            {
              index: "japanese_radio_cateList",
              title: "电台列表"
            },
            {
              index: "grammar",
              title: "语法管理"
            },
            {
              index: "question_bank",
              title: "题库管理"
            },
            {
              index: "realtest_paper",
              title: "真题管理"
            },
            {
              index: "daily_oneList",
              title: "每日一句"
            },
            {
              index: "japanese_curriculum",
              title: "课程列表"
            },
            {
              index: "auxiliary",
              title: "助词列表"
            },
            {
              index: "appointment",
              title: "约课列表"
            },
            {
              index: "open_class",
              title: "精品公开课"
            },
            {
              index: "sanctum_place",
              title: "圣地巡礼"
            }
          ]
        },

        {
          icon: "icon iconfont el-icon-s-management",
          index: "Korean",
          title: "韩语APP",
          subs: [
            {
              index: "korean_resources",
              title: "素材库"
            },
            {
              index: "forty_notes",
              title: "四十音列表"
            },
            {
              index: "korean_radio_cateList",
              title: "电台列表"
            },
            {
              index: "korean_grammar",
              title: "语法管理"
            },
            {
              index: "korean_question_bank",
              title: "题库管理"
            },
            {
              index: "korean_realtest_paper",
              title: "真题管理"
            },
            {
              index: "korean_daily_oneList",
              title: "每日一句"
            },
            {
              index: "korean_curriculum",
              title: "课程列表"
            },
            {
              index: "korean_auxiliary",
              title: "阶称大解析"
            },
            {
              index: "korean_appointment",
              title: "约课列表"
            },
            {
              index: "korean_open_class",
              title: "精品公开课"
            },
            {
              index: "korean_sanctum_place",
              title: "玩转韩国"
            },
            {
              index: "sound_rule",
              title: "音变规则"
            },
            {
              index: "idol",
              title: "爱豆列表"
            },
            {
              index: "good_play",
              title: "好剧列表"
            }
          ]
        },
        {
          icon: "icon iconfont el-icon-s-management",
          index: "French",
          title: "法语APP",
          subs: [
            {
              index: "french_resources",
              title: "素材库"
            },
            // {
            //   index: "french_wordResources",
            //   title: "单词库"
            // },
            {
              index: "french_onetoone_appointment",
              title: "法语一对一"
            },
            {
              index: "french_wordList",
              title: "法语词书"
            },
            {
              index: "french_alphabet",
              title: "法语字母"
            },
            {
              index: "questionbank_management",
              title: "题库管理"
            },
            {
              index: "french_reading",
              title: "法语阅读"
            },
            {
              index: "post_management",
              title: "发帖管理"
            },
            {
              index: "comment_management",
              title: "评论管理"
            },
            {
              index: "report_management",
              title: "举报管理"
            },
            {
              index: "french_daily_oneList",
              title: "每日一句"
            },
            {
              index: "common_word",
              title: "常用词汇"
            },
            {
              index: "french_open_class",
              title: "精品公开课"
            }
          ]
        },
        {
          icon: "icon iconfont el-icon-s-management",
          index: "Spanish",
          title: "西语APP",
          subs: [
            {
              index: "spanish_resources",
              title: "素材库"
            },
            {
              index: "spanish_alphabet",
              title: "元辅音表"
            },
            {
              index: "spanish_alphabet_video",
              title: "元辅音视频"
            },
            {
              index: "spanish_screeningroom",
              title: "西语放映厅"
            }
          ]
        },
        {
          icon: "icon iconfont el-icon-s-management",
          index: "German",
          title: "德语APP",
          subs: [
            {
              index: "german_resources",
              title: "素材库"
            }
          ]
        },
        {
          icon: "icon iconfont el-icon-s-management",
          index: "material",
          title: "素材库管理",
          subs: [
            {
              index: "material_resources",
              title: "素材库"
            }
          ]
        }
      ]
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    ...mapState({
      nums_japanese: state => state.num_japanese,
      nums_french: state => state.num_french
    })
  },
  created() {
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on("collapse", msg => {
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });
    // this.getAppointmentList();
  },
  methods: {
    kk(index) {
      bus.$emit("erji", index);
      // this.getAppointmentList();
    },
    // 获取日语一对一预约列表
    getAppointmentList() {
      getCourseAppointment({
        page: 1,
        limit: 50,
        type: 1
      }).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.num_japanese = 0;
        res.data.data.forEach(element => {
          if (element.state == 1) {
            this.num_japanese++;
          }
        });
        store.commit("setNumJapanese", this.num_japanese);
      });
      // 获取法语一对一预约列表
      getCourseAppointment({
        page: 1,
        limit: 50,
        type: 3
      }).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.num_french = 0;
        res.data.data.forEach(element => {
          if (element.state == 1) {
            this.num_french++;
          }
        });
        store.commit("setNumFrench", this.num_french);
      });
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  /* top: 50px; */
  top: 0;
  bottom: 0;
  overflow-y: scroll;
}
.logo {
  color: #fff;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  /* width: 250px;
  line-height: 50px;
  font-size: 16px;
  width: 200px;
  background-color: #414855;
  color: #fff; */
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu-item {
  font-size: 12px;
}
.sidebar > ul {
  height: 100%;
}
.grid-con-icon {
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  color: #fff;
  padding-right: 2px;
}
</style>
