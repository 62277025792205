import request from "@/utils/request";

export function userInfo(data) {
  return request({
    url: "/admin/base/rUserInfo",
    method: "get",
    data
  });
}
export function rUserLogout(data) {
  return request({
    url: "/admin/base/rUserLogout",
    method: "get",
    data
  });
}